import {
  Box,
  BoxProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VisuallyHidden,
} from '@chakra-ui/react';

import { NotifBellIcon } from 'bundles/Classroom/icons';
import { FormatTime } from 'components/intl';
import { Router } from 'components/Link';
import { Notification, useNotifications } from 'lib/notifications';
import { renderRichText } from 'lib/richText';

// notifications are marked as read if the user opens the menu
export const NotificationAlertsMenu: React.FC<Pick<BoxProps, 'flex'>> = (
  styleProps,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { inbox, archived, markAsRead, markAsClicked, isLoading } =
    useNotifications('alerts');

  const anyUnread = inbox.some((n) => !n.read_at);

  const renderNotifs = (list: Notification[]) => {
    if (isLoading)
      return (
        <Box p="10" display="flex" justifyContent="center">
          <Spinner />
        </Box>
      );

    if (!list.length)
      return (
        <Text p="10" fontSize="md" textAlign="center" color="gray.500">
          No new notifications
        </Text>
      );

    return list.map((notif) => {
      const handleClick = async () => {
        await markAsClicked(notif.id);

        const url = typeof notif.extra?.url === 'string' && notif.extra.url;

        if (url) Router.push(url);
      };

      return (
        <MenuItem
          key={notif.id}
          onClick={handleClick}
          display="block"
          whiteSpace="normal"
          position="relative"
          borderBottom="1px"
          borderColor="gray.200"
        >
          <Text fontSize="sm" fontWeight="semibold" mb="0">
            {notif.title}
          </Text>
          {notif.body ? (
            <Text fontSize="sm" mb="0">
              {renderRichText(notif.body, ['mentions'])}
            </Text>
          ) : null}
          <Text color="gray.500" fontSize="sm" textAlign="right" mb="0">
            Sent <FormatTime time={notif.sent_at} />
          </Text>

          {!notif.read_at ? (
            <Box
              bg="red.500"
              w="2"
              h="2"
              rounded="full"
              position="absolute"
              right="3"
              top="1"
            >
              <VisuallyHidden>Unread</VisuallyHidden>
            </Box>
          ) : null}
        </MenuItem>
      );
    });
  };

  // box wrap solves this issue: https://github.com/chakra-ui/chakra-ui/issues/3440
  return (
    <Box {...styleProps}>
      <Menu
        gutter={0}
        closeOnBlur
        isOpen={isOpen}
        onOpen={() => {
          onOpen();
          void markAsRead();
        }}
        onClose={() => {
          onClose();
        }}
        isLazy
        computePositionOnMount // fixes the width overflowing before first render
      >
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={
            <>
              <NotifBellIcon size="50%" />
              {anyUnread && (
                <Box
                  bg="red.500"
                  w="2"
                  h="2"
                  rounded="full"
                  position="absolute"
                  left="1"
                  top="1"
                >
                  <VisuallyHidden>Unread notifications</VisuallyHidden>
                </Box>
              )}
            </>
          }
          color="gray.500"
          aria-label="Notifications"
        />

        <MenuList
          w="min(100vw, 400px)" // prevents overflow on mobile devices
          pt="0"
          color="chakra-body-text"
        >
          <Tabs>
            <TabList>
              <Tab>Inbox</Tab>
              <Tab>Archive</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0" h="400px" overflowY="auto">
                {renderNotifs(inbox)}
              </TabPanel>
              <TabPanel p="0" h="400px" overflowY="auto">
                {renderNotifs(archived)}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MenuList>
      </Menu>
    </Box>
  );
};
