import { Text } from '@chakra-ui/react';

// TODO: consider replacing this with markdown-to-jsx

const MENTION_REGEXP = /@\[([^[]+)\]\(([^(]+)\)/g;
const URL_REGEXP = /\b(https?:\/\/[^\s]+)/g;

const REPLACERS = [
  {
    name: 'mentions',
    regex: MENTION_REGEXP,
    groups: 2,
    replacer: (key, [userName, _userId]) => (
      <Text key={key} as="span" color="blue.500">
        @{userName}
      </Text>
    ),
  },
  {
    name: 'urls',
    regex: URL_REGEXP,
    groups: 1,
    replacer: (key, [url]) => (
      <Text key={key} as="a" href={url} color="blue.500">
        {url}
      </Text>
    ),
  },
] as const satisfies readonly {
  name: string;
  regex: RegExp;
  groups: number; // must match number of capturing groups in regex
  replacer: (key: number, groups: string[]) => React.ReactNode;
}[];

type ReplacerType = typeof REPLACERS[number]['name'];

const applyRegexp = (
  text: string,
  depth: number,
  out: React.ReactNode[],
  only: ReplacerType[] | undefined,
): void => {
  if (depth >= REPLACERS.length) {
    out.push(text);
    return;
  }

  const { name, regex, groups, replacer } = REPLACERS[depth];
  if (only && !only.includes(name)) {
    applyRegexp(text, depth + 1, out, only);
    return;
  }

  const parts = text.split(regex);

  if (parts.length <= 1) {
    applyRegexp(text, depth + 1, out, only);
    return;
  }

  for (let i = 0; i < parts.length; i += groups + 1) {
    if (parts[i].length > 0) applyRegexp(parts[i], depth + 1, out, only);
    if (i + 1 < parts.length) {
      out.push(replacer(out.length, parts.slice(i + 1, i + groups + 1)));
    }
  }
};

/**
 * Similar functionality to `markdown-to-jsx`, but only supports:
 * - mentions e.g. `@[username](userId)`
 * - URLs e.g. `https://example.com`
 */
export const renderRichText = (
  text: string,
  only?: ReplacerType[],
): React.ReactNode => {
  const out: React.ReactNode[] = [];
  applyRegexp(text, 0, out, only);
  return out.length <= 1 ? out[0] : <>{out}</>;
};
