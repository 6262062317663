import ReactMarkdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { Box, Link as CLink } from '@chakra-ui/react';
import { HiOutlineExternalLink as ExternalLinkIcon } from '@react-icons/all-files/hi/HiOutlineExternalLink';
import { Children, isValidElement } from 'react';

import { urlNavigationType, Link } from 'components/Link';

const MarkdownLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  children,
  ...rest
}) => {
  href ||= '#ERROR_NO_HREF';

  const [navType, cleanHref] = urlNavigationType(href);

  // TODO: also check that file extension is blank?
  return (
    <CLink
      as={Link}
      {...rest}
      href={cleanHref}
      navType={navType}
      newTabIfExternal
      role="group"
    >
      {children}
      {navType === 'external' && (
        <Box
          as={ExternalLinkIcon}
          display="inline-block"
          aria-hidden
          ml="1"
          mt="-1"
          opacity={50}
          _groupHover={{
            opacity: 100,
          }}
        />
      )}
    </CLink>
  );
};

const MarkdownBlockquote: React.FC<
  React.BlockquoteHTMLAttributes<HTMLQuoteElement>
> = ({ children }) => {
  return (
    <Box as="blockquote" p="4" my="4" bg="gray.50" borderRadius="4">
      {children}
    </Box>
  );
};

/** ul, but also handles task-lists aka checkboxes */
export const MarkdownUnorderedList: React.FC<{
  children: React.ReactNode;
}> = ({ children, ...props }) => {
  const childs = Children.toArray(children);
  // check if the first child is an `<li><input type="checkbox"/></li>`
  const firstChild = childs[0] && isValidElement(childs[0]) ? childs[0] : null;
  const firstChildChild = firstChild?.props?.children?.[0];
  const isTaskList =
    firstChild?.type === 'li' &&
    firstChildChild?.type === 'input' &&
    firstChildChild.props?.type === 'checkbox';

  if (isTaskList) {
    return (
      <ul data-type="taskList" {...props}>
        {childs.map((child, index) => {
          if (isValidElement(child) && child.type === 'li') {
            const childChild = child.props.children[0];
            const checked = childChild.props.checked;
            return (
              <li key={index} data-type="taskItem" data-checked={checked}>
                {child.props.children}
              </li>
            );
          } else {
            return child;
          }
        })}
      </ul>
    );
  }

  return <ul {...props}>{children}</ul>;
};

const markdownOptions: MarkdownToJSX.Options = {
  forceWrapper: true,
  overrides: {
    a: { component: MarkdownLink },
    blockquote: { component: MarkdownBlockquote },
    ul: { component: MarkdownUnorderedList },
  },
};

export const Markdown: React.FC<{
  children: string;
  markdownClassName?: string;
  inline?: boolean;
}> = ({ children, inline = false, markdownClassName = 'lesson-markdown' }) => {
  if (typeof children !== 'string') return null;

  return (
    <ReactMarkdown
      options={{
        ...markdownOptions,
        ...(inline
          ? { wrapper: 'span', forceInline: true }
          : { wrapper: 'div', forceBlock: true }),
      }}
      className={markdownClassName}
    >
      {children}
    </ReactMarkdown>
  );
};
